import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Result,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import connection from "../common/connection";
import LoadingError404 from "../common/LoadingError404";

export default function SignUp({ onSetState, userName, timeStarted }) {
  // console.log("loaded sign up");
  const examName = useParams()?.examName;
  const [status, setStatus] = useState("loading");
  const [password, setPassword] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const history = useHistory();
  const [errorInfo, setErrorInfo] = useState("Error occurred! try again.");
  useEffect(() => {
    if (timeStarted) return setStatus("onGoing");
    (async () => {
      try {
        const examNamesPasswords = (await connection.get("/exams"))?.data;
        const examNamesList = examNamesPasswords.map(
          ({ examName }) => examName
        );
        // console.log(examNamesList);
        if (examNamesList.indexOf(examName) === -1) return setStatus("404");
        const { password } = examNamesPasswords.filter(
          (obj) => obj.examName === examName
        )[0];
        setPassword(password);
        setStatus("render");
      } catch (err) {
        console.error(err);
        setStatus("error");
      }
    })();
  }, []);

  const onFinish = (values) => {
    const data = {
      email: values.email,
      firstName: values.fname,
      lastName: values.lname,
      phoneNo: values.tel,
      examName: examName,
    };
    (async () => {
      try {
        setSubmitStatus("onSubmit");
        const resp = await connection.post("users/exam-signup", data);
        if (resp.status === 201) {
          sessionStorage.setItem("examName", examName);
          sessionStorage.setItem("userName", data.email);
          onSetState({ userName: data.email, examName: examName });
          history.push("/exam-start");
        }
      } catch (err) {
        console.error(err);
        setSubmitStatus("error");
        if ("response" in err && err.response.status === 403)
          return setErrorInfo("You've already written this exam!");
        return setErrorInfo("Error occurred! try again.");
      }
    })();
    // console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };

  if (status !== "render")
    return <LoadingError404 status={status}></LoadingError404>;

  return (
    <Layout className="wrapper__section">
      <Row justify="center" style={{ textAlign: "center" }}>
        <Col
          xs={22 + 2}
          sm={19 + 2}
          md={16 + 2}
          lg={13 + 2}
          xl={10 + 2}
          xxl={8 + 2}>
          <Typography.Title level={3}>
            {
              examName
              // .split("_").join(" ")
            }
          </Typography.Title>
          <Typography.Text>
            Welcome to the Cambridge College Exam Portal 🔥 You can now Test
            your knowledge and improve on your performance. Take the Exam Now!
            Good Luck 👍
          </Typography.Text>
        </Col>
      </Row>
      <br></br>
      <Row justify="center">
        <Col xs={22} sm={19} md={16} lg={13} xl={10} xxl={8}>
          <Form
            layout="vertical"
            name="user-data"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is not a valid email!",
                  type: "email",
                },
              ]}>
              <Input />
            </Form.Item>

            <Form.Item
              label="First Name"
              name="fname"
              rules={[
                { required: true, message: "Please input your first name!" },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lname"
              rules={[
                { required: true, message: "Please input your last name!" },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone No."
              name="tel"
              rules={[
                {
                  required: true,
                  message: "Not a valid phone number!",
                  pattern: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm,
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              validateTrigger="onSubmit"
              label="Exam Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter the exam password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === password) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Password you entered is Wrong!");
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Row justify="center">
                <Spin spinning={submitStatus === "onSubmit" ? true : false}>
                  <Button type="primary" htmlType="submit">
                    Sign Up
                  </Button>
                </Spin>
              </Row>
              {submitStatus === "error" ? (
                <Row justify="center" style={{ paddingTop: "16px" }}>
                  <Alert message={errorInfo} type="error" showIcon />
                </Row>
              ) : null}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Layout>
  );
}
