import { Alert, Button, Divider, Row, Spin, Typography } from "antd";
import HTMLReactParser from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import connection from "../common/connection";
import LoadingError404 from "../common/LoadingError404";
import SectionWrapper from "../common/SectionWrapper";

export default function Start({ examName, onSetState, timeStarted }) {
  const [status, setStatus] = useState("loading");
  const [startPage, setStartPage] = useState(null);
  const [spin, setSpin] = useState(false);
  const history = useHistory();
  const startExam = () => {
    setSpin(true);
    let currentTime = null;
    connection
      .get("/time")
      .then((res) => {
        // console.log(res.data);
        currentTime = new Date(JSON.parse(res.data)).valueOf();
      })
      .catch((err) => {
        console.error(err);
        currentTime = Date.now();
      })
      .finally(() => {
        sessionStorage.setItem("timeStarted", JSON.stringify(currentTime));
        onSetState({ timeStarted: currentTime }, () => {
          history.push("exam-ongoing/1");
        });
      });
  };
  useEffect(() => {
    if (timeStarted) return setStatus("onGoing");
    sessionStorage.removeItem("answers");
    (async () => {
      try {
        const resp = await connection.get(
          `exams/get?examName=${encodeURIComponent(examName)}`
        );
        // console.log(resp);
        sessionStorage.setItem(
          "sections",
          JSON.stringify(resp?.data?.sections)
        );
        setStartPage(resp?.data?.startPage);
        setStatus("render");
      } catch (err) {
        console.error(err);
        setStatus("error");
      }
    })();
  }, []);

  if (status !== "render")
    return <LoadingError404 status={status}></LoadingError404>;

  return (
    <SectionWrapper>
      <Typography.Title level={3} style={{ textAlign: "center" }}>
        {examName}
      </Typography.Title>
      {HTMLReactParser(startPage)}
      <Divider></Divider>
      <Alert
        style={{ margin: "16px 8px 8px 8px" }}
        message="Do not reload the tab while answering the exam."
        type="warning"
        showIcon
      />
      <Alert
        style={{ margin: "8px 8px 32px" }}
        message="Do not go back to previous page while answering the exam."
        type="warning"
        showIcon
      />
      <Row justify="center">
        <Spin spinning={spin}>
          <Button type="primary" onClick={startExam}>
            Start Exam
          </Button>
        </Spin>
      </Row>
    </SectionWrapper>
  );
}
