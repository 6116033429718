import { Button, Result, Spin } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import NotFound from "./NotFound";

export default function LoadingError404({ status }) {
  const history = useHistory();
  if (status === "loading") {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Spin size="large" />
      </div>
    );
  }

  if (status === "error") {
    return (
      <Result
        status="error"
        title="Error Occurred! Try again."
        extra={
          <Button
            key="error-btn"
            type="primary"
            onClick={() => {
              history.push("/");
            }}>
            Back Home
          </Button>
        }
      />
    );
  }
  if (status === "onGoing") {
    return (
      <Result
        status="warning"
        title="Exam on going! Please click below to view exam."
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => history.push("/exam-ongoing/1")}>
            Go to Exam
          </Button>
        }></Result>
    );
  }
  return <NotFound></NotFound>;
}
