import {
  Divider,
  Layout,
  Typography,
  Button,
  Row,
  Col,
  Spin,
  Alert,
} from "antd";
import { Handle } from "rc-slider";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { HandleSubmitContext, StateContext } from "./ExamWrapper";
import SectionWrapper from "../common/SectionWrapper";
import QuestionWrapper from "./QuestionWrapper";
import HTMLReactParser from "html-react-parser";

export default function Section({
  sectionNo,
  sectionDetails,
  innerSections,
  last,
}) {
  const state = useContext(StateContext);
  const history = useHistory();
  const goBackward = () => {
    history.push(`/exam-ongoing/${sectionNo}`);
  };
  const goForward = () => {
    history.push(`/exam-ongoing/${sectionNo + 2}`);
  };
  const handleSubmit = useContext(HandleSubmitContext);
  return (
    <SectionWrapper>
      <Typography.Title level={3}>{`Section ${
        sectionNo + 1
      }`}</Typography.Title>
      <Typography.Title
        level={4}
        style={{ fontWeight: "normal", marginTop: "8px" }}>
        {HTMLReactParser(sectionDetails)}
      </Typography.Title>
      {innerSections.map(({ questionsList, description }, innerSectionNo) => (
        <React.Fragment key={`is-${innerSectionNo}-frag`}>
          {description ? (
            <React.Fragment key={`is-${innerSectionNo}-desc-frag`}>
              <Typography.Title level={4} key={`is-${innerSectionNo}-title`}>
                {description.title ? description.title : null}
              </Typography.Title>
              <Typography.Paragraph key={`is-${innerSectionNo}-paragraph`}>
                {description.paragraph ? description.paragraph : null}
              </Typography.Paragraph>
            </React.Fragment>
          ) : null}
          {/* <br></br> */}
          {questionsList.map(({ question, answersList }, questionNo) => (
            <React.Fragment
              key={`is-${innerSectionNo}-q-${questionNo + 1}-frag`}>
              <br key={`is-${innerSectionNo}-q-${questionNo + 1}-br`}></br>
              <QuestionWrapper
                key={`is-${innerSectionNo}-q-${questionNo + 1}`}
                sectionNo={sectionNo}
                innerSectionNo={innerSectionNo}
                questionNo={questionNo}
                key={questionNo}
                question={question}
                answersList={answersList}></QuestionWrapper>
            </React.Fragment>
          ))}
          <Divider key={`is-${innerSectionNo}-divider`}></Divider>
        </React.Fragment>
      ))}
      <Row justify="space-between">
        <Col>
          <Button
            type="primary"
            disabled={sectionNo === 0 ? true : false}
            onClick={goBackward}>
            Previous Section
          </Button>
        </Col>
        {state.error ? (
          <Col style={{ alignSelf: "center" }}>
            <Alert message="Error Occurred! Try again." type="error" showIcon />
          </Col>
        ) : null}
        <Col>
          {last ? (
            <Spin spinning={state.answersSubmitted}>
              <Button type="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Spin>
          ) : (
            <Button type="primary" onClick={goForward}>
              Next section
            </Button>
          )}
        </Col>
      </Row>
    </SectionWrapper>
  );
}
