import { Layout } from "antd";
import React, { Children } from "react";

export default function SectionWrapper({ children, style }) {
  return (
    <Layout className="wrapper__section" style={style}>
      {children}
    </Layout>
  );
}
