import { Button, Row, Typography } from "antd";
import React, { useState } from "react";
import Timer from "react-compound-timer/build";

export default function Countdown({ leftTime, setTimeEnd }) {
  const [color, setColor] = useState("#1890ff");
  return (
    <Timer
      initialTime={leftTime}
      direction="backward"
      checkpoints={[
        {
          time: 1200000,
          callback: () => setColor("#faad14"),
        },
        {
          time: 300000,
          callback: () => setColor("#f5222d"),
        },
        {
          time: 0,
          callback: () => setTimeEnd(true),
        },
      ]}>
      {({ timerState }) => {
        if (timerState === "STOPPED") {
          setTimeEnd(true);
        }
        return (
          <Row justify="center">
            <div
              style={{
                fontSize: "22px",
                padding: "8px",
                color: "white",
                padding: "8px 16px",
                backgroundColor: color,
                borderRadius: "40px",
                boxShadow: ` 0 4px 10px ${color}80`,
              }}>
              <Timer.Hours formatValue={(value) => `${value}h `} />
              <Timer.Minutes
                formatValue={(value) => `${value < 10 ? `0${value}` : value}m `}
              />
              <Timer.Seconds
                formatValue={(value) => `${value < 10 ? `0${value}` : value}s`}
              />
            </div>
          </Row>
        );
      }}
    </Timer>
  );
}
// {
//   /* <div
//               style={{
//                 color: "white",
//                 padding: "8px 16px",
//                 backgroundColor: "#1890ff",
//                 borderRadius: "40px",
//                 boxShadow: " 0 2px 8px #1890ff15",
//               }}> */
// }
// {
//   /* <Row> */
// }
// {
//   /* </Row> */
// }
// {
//   /* </div> */
// }
