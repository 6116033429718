import { Layout, Typography } from "antd";
import React, { useEffect } from "react";
import SectionWrapper from "./SectionWrapper";

export default function Done({ resetState }) {
  useEffect(() => {
    sessionStorage.clear();
    resetState();
  }, []);
  return (
    <SectionWrapper>
      <Typography.Title level={3}>Thank you!</Typography.Title>
      <Typography.Paragraph>
        {`Check your e mail 🚀 for the Exam Results! Good Luck 🤟
          Call / WhatsApp on +94 (76) 800 0222 or chat with us on www.ccbm.lk to obtain more info on our courses 🔥`}
      </Typography.Paragraph>
    </SectionWrapper>
  );
}
