import React, { Component } from "react";
import "./App.less";
import { Route, Switch } from "react-router-dom";
import Done from "./features/common/Done";
import ExamWrapper from "./features/Exam/ExamWrapper";
import ScrollToTop from "./features/common/ScrollToTop";
import ExamSelection from "./features/ExamSelection/ExamSelection";
import { Dropdown, Menu, Button, Layout, Row, Typography, Col } from "antd";
import { Header } from "antd/lib/layout/layout";
import Logo from "./Logo.png";
import { MenuOutlined } from "@ant-design/icons";
import NotFound from "./features/common/NotFound";
import SignUp from "./features/SignUp/SignUp";
import Start from "./features/Start/Start";
import ResultsWrapper from "./features/Results/ResultsWrapper";
// import ExamRangeSelection from "./features/ExamSelection/ExamRangeSelection";

const { Footer } = Layout;
const menu = (
	<Menu>
		<Menu.Item className="dropdown-container">
			<a className="navbar-link" target="_blank" rel="noopener noreferrer" href="https://ccbm.lk/cim-uk/">
				CIM UK
			</a>
		</Menu.Item>
		<Menu.Item className="dropdown-container">
			<a className="navbar-link" target="_blank" rel="noopener noreferrer" href="https://ccbm.lk/contact-us/">
				Contact Us
			</a>
		</Menu.Item>
	</Menu>
);

export default class App extends Component {
	initialState = {
		examName: sessionStorage.getItem("examName"),
		userName: sessionStorage.getItem("userName"),
		timeStarted: sessionStorage.hasOwnProperty("timeStarted")
			? new Date(JSON.parse(sessionStorage.getItem("timeStarted"))).valueOf()
			: undefined,
		// examList: sessionStorage.hasOwnProperty("examList")
		// ? JSON.parse(sessionStorage.getItem("examList"))
		// : undefined,
		// pagination: sessionStorage.getItem("pagination"),
	};
	constructor(props) {
		super(props);

		this.state = this.initialState;
	}
	onSetState = (data, callBack) => {
		this.setState(data, callBack);
	};
	resetState = (callback) => {
		const initialState = {
			examName: undefined,
			userName: undefined,
			timeStarted: undefined,
		};
		// console.log("reset");
		this.setState(initialState, callback);
	};

	render() {
		return (
			<>
				<Header className="header">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://examengine.ccbm.lk/"
						style={{
							height: "100%",
							display: "flex",
							alignItems: "center",
						}}>
						<img className="header-logo" src={Logo}></img>
					</a>
					<Dropdown placement="bottomRight" overlay={menu} arrow trigger={["click"]}>
						<Button type="text" icon={<MenuOutlined />} size="large" style={{ fontFamily: "Inter" }}></Button>
					</Dropdown>
				</Header>

				<div className="App">
					<div className="wrapper__main">
						<ScrollToTop />

						<Switch>
							{/* <Route
                exact
                path="/"
                render={(props) => (
                  <ExamRangeSelection
                    {...props}
                    onSetState={this.onSetState}
                    resetState={this.resetState}
                  />
                )}></Route> */}
							<Route
								exact
								path="/"
								render={(props) => (
									<ExamSelection
										{...props}
										// examList={this.state.examList}
										// pagination={this.state.pagination}
										resetState={this.resetState}
									/>
								)}></Route>
							<Route
								path="/exams/:examName"
								render={(props) => (
									<SignUp
										{...props}
										userName={this.state.userName}
										onSetState={this.onSetState}
										timeStarted={this.state.timeStarted}
									/>
								)}></Route>
							<Route path="/results" render={(props) => <ResultsWrapper {...props} />}></Route>
							<Route exact path="/exam-done" render={() => <Done resetState={this.resetState} />}></Route>

							{this.state.userName && this.state.examName ? (
								<>
									<Route
										path={`/exam-start`}
										render={(props) => (
											<Start
												{...props}
												examName={this.state.examName}
												onSetState={this.onSetState}
												timeStarted={this.state.timeStarted}
											/>
										)}></Route>
									<Route
										path={`/exam-ongoing`}
										render={(props) => (
											<ExamWrapper
												{...props}
												examName={this.state.examName}
												userName={this.state.userName}
												timeStarted={this.state.timeStarted}
											/>
										)}></Route>
								</>
							) : null}

							<Route component={NotFound}></Route>
						</Switch>
					</div>
					<div className="footer">
						{/* <Row justify="center">
              <Typography.Text style={{ fontSize: 12 }}>
                All Rights Reserved. Cambridge College
              </Typography.Text>
            </Row> */}
						<Row justify="center">
							<Typography.Text style={{ fontSize: 12 }}>Powered By</Typography.Text>
						</Row>
						<Row justify="center">
							<a href="https://seamlessc.com">
								<img src="http://ccbm.lk/wp-content/uploads/2022/05/SeamlessC.png" width="120 "></img>
							</a>{" "}
						</Row>
					</div>
				</div>
			</>
		);
	}
}
