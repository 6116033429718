import { Checkbox } from "antd";
import React, { useContext } from "react";
import { StateContext } from "./ExamWrapper";
import Answer from "./Answer";

export default function AnswersWrapper({
  answersList,
  disabled,
  onChange,
  answer,
}) {
  const { loaded } = useContext(StateContext);
  return (
    <Checkbox.Group
      // defaultChecked={loaded ? true : false}
      value={answer}
      disabled={disabled}
      onChange={onChange}>
      {answersList.map((item) => (
        <Answer label={item.label} key={item.value} value={item.value}></Answer>
      ))}
    </Checkbox.Group>
  );
}
