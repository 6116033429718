import { Divider, Typography } from "antd";
import React, { useContext } from "react";
import HTMLReactParser from "html-react-parser";
import ResultsQuestions from "./ResultsQuestions";
export default function ResultSections({
  sectionNo,
  sectionDetails,
  innerSections,
  last,
}) {
  return (
    <>
      <Typography.Title level={3}>{`Section ${
        sectionNo + 1
      }`}</Typography.Title>
      <Typography.Title
        level={4}
        style={{ fontWeight: "normal", marginTop: "8px" }}>
        {HTMLReactParser(sectionDetails)}
      </Typography.Title>
      {innerSections.map(({ questionsList, description }, innerSectionNo) => (
        <React.Fragment key={`is-${innerSectionNo}-frag`}>
          {description ? (
            <React.Fragment key={`is-${innerSectionNo}-desc-frag`}>
              <Typography.Title level={4} key={`is-${innerSectionNo}-title`}>
                {description.title ? description.title : null}
              </Typography.Title>
              <Typography.Paragraph key={`is-${innerSectionNo}-paragraph`}>
                {description.paragraph ? description.paragraph : null}
              </Typography.Paragraph>
            </React.Fragment>
          ) : null}
          {/* <br></br> */}
          {questionsList.map(({ question, answersList }, questionNo) => (
            <React.Fragment
              key={`is-${innerSectionNo}-q-${questionNo + 1}-frag`}>
              <br key={`is-${innerSectionNo}-q-${questionNo + 1}-br`}></br>
              <ResultsQuestions
                key={`is-${innerSectionNo}-q-${questionNo + 1}`}
                sectionNo={sectionNo}
                innerSectionNo={innerSectionNo}
                questionNo={questionNo}
                key={questionNo}
                question={question}
                answersList={answersList}></ResultsQuestions>
            </React.Fragment>
          ))}
          {!last && innerSections.length === innerSectionNo + 1 ? (
            <Divider key={`is-${innerSectionNo}-divider`}></Divider>
          ) : null}
          {!last && !(innerSections.length === innerSectionNo + 1) ? (
            <br></br>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );
}
