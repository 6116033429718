import { Button, Card, Col, Divider, Row, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import connection from "../common/connection";
import LoadingError404 from "../common/LoadingError404";
import SectionWrapper from "../common/SectionWrapper";
import banner from "./banner.jpg";

export default function ExamSelection({ resetState, pagination }) {
  const gridStyle = {
    borderRadius: "8px",
    cursor: "pointer",
    width: "100%",
    textAlign: "left",
    height: "100%",
  };
  const history = useHistory();

  const examSelected = (examName) => () => {
    sessionStorage.setItem("fromWhere", "mainPage");
    history.push(`/exams/${examName}`);
  };
  const [status, setStatus] = useState("loading");
  const [examList, setExamList] = useState(null);
  useEffect(() => {
    sessionStorage.clear();
    resetState();
    sessionStorage.setItem("fromWhere", "mainPage");
    (async () => {
      try {
        const resp = await connection.get("exams");

        if (resp?.data?.length > 0) {
          const examNames = resp.data.map(({ examName }) => examName);
          const sortedExamNames = examNames.sort();
          setExamList(sortedExamNames);
          setStatus("render");
          // sessionStorage.setItem("examList", JSON.stringify(sortedExamNames));
        } else {
          setStatus("empty");
        }
      } catch (err) {
        console.error(err);
        setStatus("error");
      }
    })();
  }, []);
  if (status !== "render")
    return <LoadingError404 status={status}></LoadingError404>;
  return (
    <>
      <SectionWrapper style={{ padding: "0" }}>
        <img
          src={banner}
          style={{
            borderRadius: "8px",
            width: "100%",
          }}></img>
      </SectionWrapper>
      <SectionWrapper>
        <Typography.Title level={3} style={{ textAlign: "center" }}>
          Select an Exam to start
        </Typography.Title>
        <Divider></Divider>
        <Row justify="space-around" gutter={[0, 32]}>
          {examList.map((item, index) => (
            // <Space>
            <Col key={`col-${index}`} xs={22} sm={22} md={11} lg={11} xl={11}>
              <Card.Grid style={gridStyle} onClick={examSelected(item)}>
                <Typography.Text style={{ fontWeight: 600 }}>
                  {
                    item
                    // .split("_").join(" ")
                  }
                </Typography.Text>
              </Card.Grid>
            </Col>
            // </Space>
          ))}
        </Row>
      </SectionWrapper>
    </>
  );
}
