import React, { useEffect, useReducer, useRef, useState } from "react";
// import sectionData from "../utils/sectionData";
import produce from "immer";
import { Affix, Button, Col, Layout, Row, Typography } from "antd";
import connection from "../common/connection";
import _ from "lodash";
import { Route, useHistory, useParams } from "react-router-dom";
import Section from "./Section";
import Countdown from "../Countdown/Countdown";
import LoadingError404 from "../common/LoadingError404";
export const StateContext = React.createContext();
export const DispatchContext = React.createContext();
export const HandleSubmitContext = React.createContext();
const twoHours = 7200000;
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case "SET_ANSWERS":
        draft.loaded = true;
        let section = draft.sections[action.sectionNo];
        section.innerSections[action.innerSectionNo].questionsList[
          action.questionNo
        ].givenAnswers = action.answers;
        draft.sections[action.sectionNo] = section;
        break;
      case "SET_ALL_ANSWERS":
        // console.log(action.payload);
        draft.sections = action.payload;
        draft.loaded = true;
        break;
      case "SET_ANSWERS_SUBMITTED":
        // draft.loaded = false;
        draft.answersSubmitted = action.payload;
        break;
      case "SET_ERROR":
        // draft.loaded = false;
        draft.error = action.payload;
        break;
      case "SET_DISABLED":
        // draft.loaded = false;
        draft.disabled = action.payload;

        break;
      case "reset":
        // draft.loaded = false;
        return action.payload;
        break;
      default:
        throw new Error();
    }
  });
const defaultAnswerPopulator = (sections) =>
  sections?.map(({ innerSections }) => ({
    innerSections: innerSections.map(({ questionsList }) => ({
      questionsList: questionsList.map(() => ({
        givenAnswers: [""],
      })),
    })),
  }));
function ExamWrapper({ match, examName, userName, timeStarted }) {
  const { current: sections } = useRef(
    JSON.parse(sessionStorage.getItem("sections"))
  );
  const initialState = {
    sections: defaultAnswerPopulator(sections),
    loaded: false,

    disabled: false,
    answersSubmitted: false,
    error: false,
  };
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const reset = () => dispatch({ type: "reset", payload: initialState });
  const { current: isPrevAnswers } = useRef(
    sessionStorage.hasOwnProperty(`answers`)
  );
  const [leftTime, setLeftTime] = useState(null);
  const [countdown, setCountdown] = useState(false);
  const [timeEnd, setTimeEnd] = useState(false);

  useEffect(() => {
    let currentTime = null;
    connection
      .get("/time")
      .then((res) => {
        // console.log(res.data);
        currentTime = new Date(JSON.parse(res.data)).valueOf();
      })
      .catch((err) => {
        console.error(err);
        currentTime = Date.now();
      })
      .finally(() => {
        const spentTime = currentTime - timeStarted;
        if (spentTime >= twoHours) return setLeftTime(0);
        setLeftTime(twoHours - spentTime);
      });
  }, []);

  useEffect(() => {
    if (leftTime === 0) setTimeEnd(true);
  }, [leftTime]);

  useEffect(() => {
    if (timeEnd === true) {
      dispatch({ type: "SET_DISABLED", payload: true });
    }
  }, [timeEnd]);
  useEffect(() => {
    if (state.loaded) {
      const storingValue = JSON.stringify(state.sections);
      // console.log(examName);
      sessionStorage.setItem(`answers`, storingValue);
    }
  }, [state]);

  const handleSubmit = () => {
    const answerData = {
      sections: state.sections,
      userName: userName,
      examName: examName,
    };
    (async (data) => {
      try {
        dispatch({ type: "SET_DISABLED", payload: true });
        dispatch({ type: "SET_ANSWERS_SUBMITTED", payload: true });
        const resp = await connection.post("/results/", answerData);
        // console.log(resp);
        resp.status === 201 && history.push("/exam-done");
      } catch (err) {
        if (!timeEnd) {
          dispatch({ type: "SET_DISABLED", payload: false });
        }

        dispatch({ type: "SET_ERROR", payload: true });
        dispatch({ type: "SET_ANSWERS_SUBMITTED", payload: false });
      }
    })(answerData);

    // console.log(_.merge(sectionData, state.sections));
  };

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <HandleSubmitContext.Provider value={handleSubmit}>
          {examName && userName ? (
            <>
              {isPrevAnswers && !state.loaded ? (
                <LoadPrevious dispatch={dispatch} />
              ) : null}
              {sections?.map((item, index) => (
                <Route
                  key={index}
                  path={`${match.url}/${index + 1}/`}
                  render={(props) => (
                    <Section
                      sectionNo={index}
                      sectionDetails={item.sectionDetails}
                      innerSections={item.innerSections}
                      last={
                        sections.length === index + 1 ? true : false
                      }></Section>
                  )}></Route>
              ))}

              {leftTime !== null ? (
                <Affix offsetBottom={16}>
                  <Countdown
                    leftTime={leftTime}
                    setTimeEnd={setTimeEnd}></Countdown>
                </Affix>
              ) : null}
            </>
          ) : (
            <LoadingError404></LoadingError404>
          )}
        </HandleSubmitContext.Provider>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );

  function LoadPrevious({ dispatch }) {
    const currentRef = useRef(null);
    useEffect(() => {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
    }, []);
    return (
      <div>
        <div ref={currentRef}></div>
        <Layout className="wrapper__section">
          <Typography.Title level={5} type={"warning"}>
            You have Previously saved answers.
          </Typography.Title>
          <Button
            type="primary"
            onClick={() =>
              dispatch({
                type: "SET_ALL_ANSWERS",
                payload: JSON.parse(sessionStorage.getItem(`answers`)),
              })
            }>
            Click here to load
          </Button>
        </Layout>
      </div>
    );
  }
}

export default ExamWrapper;
