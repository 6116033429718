import { Col, Divider, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import connection from "../common/connection";
import LoadingError404 from "../common/LoadingError404";
import SectionWrapper from "../common/SectionWrapper";
import useQuery from "../utils/useQuery";
import ResultSections from "./ResultsSections";
export const ResultStateContext = React.createContext();

export default function ResultsWrapper() {
  const queryParams = useQuery();
  // console.log(queryParams);
  const [state, setState] = useState(null);
  const [status, setStatus] = useState("loading");
  const userName = queryParams.get("userName");
  const examName = queryParams.get("examName");
  const tryNo = queryParams.get("tryNo");
  useEffect(() => {
    (async () => {
      try {
        const resp = await connection.get("results", {
          params: { userName, examName, tryNo },
        });
        // console.log(resp);
        setState(resp.data);
        setStatus("render");
      } catch (err) {
        console.error(err);
        if (err?.response?.status === 400) return setStatus("");
        setStatus("error");
      }
    })();
  }, []);
  if (status !== "render")
    return <LoadingError404 status={status}></LoadingError404>;
  return (
    <div>
      <ResultStateContext.Provider value={state}>
        <SectionWrapper>
          <Typography.Title level={3}>{examName}</Typography.Title>
          <br></br>
          <Row>
            <Typography.Title level={5}>
              You achieved {state.results} out of {state.total}.
            </Typography.Title>
          </Row>
          <Row>
            <Col span={7}>
              <Typography.Text>Name</Typography.Text>
            </Col>
            <Col>
              <Typography.Text
                strong>{`${state.firstName} ${state.lastName}`}</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Typography.Text>Email</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong>{state.email}</Typography.Text>
            </Col>
          </Row>

          <Row>
            <Col span={7}>
              <Typography.Text>Phone No.</Typography.Text>
            </Col>
            <Col>
              <Typography.Text strong>{state.phoneNo}</Typography.Text>
            </Col>
          </Row>
          <Divider></Divider>
          {state.sections?.map((item, index) => (
            <ResultSections
              sectionNo={index}
              sectionDetails={item.sectionDetails}
              innerSections={item.innerSections}
              last={
                state.sections.length === index + 1 ? true : false
              }></ResultSections>
          ))}
        </SectionWrapper>
      </ResultStateContext.Provider>
    </div>
  );
}
