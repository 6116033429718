import React, { useContext } from "react";
import { Button, Layout, Row, Typography, Col } from "antd";
import AnswersWrapper from "./AnswersWrapper";
import { StateContext, DispatchContext } from "./ExamWrapper";

export default function QuestionWrapper({
  sectionNo,
  innerSectionNo,
  questionNo,
  answersList,
  question,
}) {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const onChange = (values) => {
    dispatch({
      type: "SET_ANSWERS",
      sectionNo: sectionNo,
      innerSectionNo: innerSectionNo,
      questionNo: questionNo,
      answers: values,
    });
  };
  return (
    <Layout.Content>
      <Col>
        <Row>
          <Typography.Paragraph style={{ fontSize: "16px" }} strong>
            {`${questionNo + 1}. ${question}`}
          </Typography.Paragraph>
        </Row>
        <Row style={{ margin: "0 8px" }}>
          <AnswersWrapper
            answersList={answersList}
            answer={
              state.sections[sectionNo].innerSections[innerSectionNo]
                .questionsList[questionNo].givenAnswers
            }
            onChange={onChange}
            disabled={state.disabled}></AnswersWrapper>
        </Row>
      </Col>
    </Layout.Content>
  );
}
