import { Checkbox, Row } from "antd";
import React from "react";

export default function ResultsAnswers({ answersList, answer }) {
  return (
    <Checkbox.Group value={answer}>
      {answersList.map((item) => (
        <Answer label={item.label} key={item.value} value={item.value}></Answer>
      ))}
    </Checkbox.Group>
  );
}

function Answer({ label, value }) {
  return (
    <Row>
      <Checkbox
        value={value}
        style={{
          margin: "4px 0",
        }}>
        {`${value}) ${label}`}
      </Checkbox>
    </Row>
  );
}
