import React, { useContext } from "react";
import { Layout, Row, Typography, Col, List, Card } from "antd";
import ResultsAnswers from "./ResultsAnswers";
import { ResultStateContext } from "./ResultsWrapper";

export default function ResultsQuestions({
  sectionNo,
  innerSectionNo,
  questionNo,
  answersList,
  question,
}) {
  const state = useContext(ResultStateContext);
  const answerStatus =
    state.sections[sectionNo].innerSections[innerSectionNo].questionsList[
      questionNo
    ].answerStatus;
  const givenAnswers =
    state.sections[sectionNo].innerSections[innerSectionNo].questionsList[
      questionNo
    ].givenAnswers;
  const correctAnswers =
    state.sections[sectionNo].innerSections[innerSectionNo].questionsList[
      questionNo
    ].correctAnswers;

  return (
    <Layout.Content>
      <Col>
        <Row>
          <Typography.Paragraph style={{ fontSize: "16px" }} strong>
            {`${questionNo + 1}. ${question}`}
          </Typography.Paragraph>
        </Row>
        <Row style={{ margin: "0 8px" }}>
          <ResultsAnswers
            answersList={answersList}
            answer={givenAnswers}></ResultsAnswers>
        </Row>
        <Row>
          <Typography.Paragraph style={{ margin: "16px" }}>
            <ColoredAnswerStatus
              answerStatus={answerStatus}></ColoredAnswerStatus>
          </Typography.Paragraph>
        </Row>
        <Row style={{ margin: "0 16px 16px 16px" }}>
          <Typography.Paragraph>{`Correct answers: ${correctAnswers.map(
            (item, index) => ` ${item}`
          )}`}</Typography.Paragraph>
        </Row>
      </Col>
    </Layout.Content>
  );
}
function ColoredAnswerStatus({ answerStatus }) {
  if (answerStatus === "incorrect") {
    return (
      <span>
        Your answer is{" "}
        <span strong style={{ color: "#f5222d" }}>
          {answerStatus}!
        </span>
      </span>
    );
  }
  if (answerStatus === "incomplete") {
    return (
      <span>
        Your answer is{" "}
        <span strong style={{ color: "#faad14" }}>
          {answerStatus}!
        </span>
      </span>
    );
  }
  if (answerStatus === "correct") {
    return (
      <span>
        Your answer is{" "}
        <span strong style={{ color: "#52c41a" }}>
          {answerStatus}!
        </span>
      </span>
    );
  }
}
