import { Checkbox, Row, Typography } from "antd";
import Col from "antd/lib/grid/col";
import React from "react";

export default function Answer({ label, value }) {
  return (
    <Row>
      <Checkbox
        value={value}
        style={{
          //   whiteSpace: "break-spaces",
          margin: "4px 0",
        }}>
        {`${value}) ${label}`}
      </Checkbox>
    </Row>
  );
}
